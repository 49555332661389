@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}
.logo-image {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: auto;
  height: 48px;
  object-fit: cover;
  object-position: center;
}
